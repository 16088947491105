// src/pages/Home.js
import React from 'react';

const Home = () => {
  return (
    <div>
      Hello world1
    </div>
  );
};

export default Home;
